.splash-screen {
    width: 100%;
    height: 100%;
    position: fixed;
    align-items: center;
    justify-content: center;
    background: linear-gradient(27deg, $primary800 0%, $primary700 100%)
}

.center-icon {
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;  
    text-align: center;  
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }