@import './../../../global/global-color-styles.scss';

.scrubber-marker {
  position: absolute;
  top: 0.1%;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .scrubber-marker-wrapper {
      height: 22px;
      width: 22px;
      border-radius: 50%;
      background-color: $baseWhite;
      z-index: 1;
      display: grid;
      align-content: center;
      border: 2px solid $primary500;
      left: 5%;
      position: relative;

      .scrubber-marker-icon {
        width: 12px;
        height: 12px;
        margin: auto;
        stroke: $primary500;

        g > path {
          stroke: $primary500;
          width: 12px;
          height: 12px;
        }
      }
    }

    .underline {
      margin-left: 50%;
      border-left: 2px dotted $gray100;
      height: 93px;
      padding-left: 10px;
      margin-top: 18px;
      position: absolute;
    }
  }
}

.scrubber-marker-tooltip {
  line-height: 1;
}

.scrubberContainer {
  position: fixed;
  bottom: 20px;
  height: 100px;
  touch-action: pinch-zoom;
  left: 50%;

  .metricsContainer {
    top: -33%;

    &.expanded {
      @media (min-width: 769px) {
        margin-left: 400px;
      }
    }
  }

  .scrubberTimelineContainer {
    height: 100px;
    width: 80vw;
    position: absolute;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    overflow: hidden;
    margin-top: 0.5rem;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &.expanded {
      @media (min-width: 769px) {
        margin-left: 400px;
        width: calc(80vw - 400px);
      }
    }

    .scrubberPlayButton {
      height: 50%;
      width: 50px;
      position: absolute;
      top: 0px;
      left: 0px;
      background: $primary600;
      cursor: pointer;
      transition: background 300ms;

      &.playing {
        background: $gray900;

        & > svg {
          width: 17px;
          height: 17px;
          left: 18px;
        }
      }

      & > svg {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        width: 25px;
        height: 25px;
        fill: $baseWhite;
      }
    }

    .scrubberSpeedButton {
      height: 50%;
      width: 50px;
      position: absolute;
      top: 50%;
      left: 0px;
      border: 1px solid $gray300;
      background: $baseWhite;
      cursor: pointer;
      transition: background 300ms;

      & > svg {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        width: 25px;
        height: 25px;
        fill: $baseWhite;
      }
    }

    .scrubberTimeline {
      height: 100%;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;

      &:focus {
        outline: none;
      }

      &:hover {
        .time-needle {
          width: 4px;
        }
      }

      .timeNeedle {
        position: absolute;
        height: 100%;
        width: 2px;
        left: 50%;
        transform: translateX(-50%);
        transition: width 200ms;
        background: $gray400;

        &.active {
          background: $primary600;
        }

        &.warning {
          background: $warning600;
        }
      }

      .scrubberTreadContainer {
        position: relative;
        display: inline-block;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(15px);

        .scrubberTimeSection {
          position: relative;
          display: inline-block;
          height: 100%;
          top: -15px;

          .timeString,
          .dateString {
            position: absolute;
            top: 15px;
            left: -15px;
            font-size: 13px;
            color: $primary600;
          }

          .timeString {
            top: 20px;
            left: -15px;
          }

          .dateString {
            top: 33px;
            left: -15px;
            opacity: 0.5;
          }

          .minuteLinesContainer {
            display: flex;
            width: 100%;
            height: 14px;
            border-right: 1px solid $primary600;

            .minuteLine {
              flex-grow: 1;
              height: 8px;
              border-right: 1px solid $primary600;

              &:last-child {
                border-right: initial;
              }
            }
          }
        }

        .scrubberSpeedChartContainer {
          position: absolute;
          height: 100%;
        }
      }
    }
  }
}

// *** New Scrubber ***

.scrubber-container {
  position: fixed;
  bottom: 0;
  touch-action: pinch-zoom;
  width: 100%;
  height: 214px;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
  border-radius: 12px 12px 0 0;
  border: 1px solid $gray300;
  background: $baseWhite;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06);
  transition: margin-left 250ms, width 250ms;

  &.chakra-stack {
    display: block;
  }

  &.shrunk {
    @media (min-width: 769px) {
      margin-left: 400px;
      width: calc(100% - 400px);
    }
  }

  .scrubber-controls-container {
    position: fixed;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: -30px;
    display: flex;
    width: 50%;
    gap: 12px;

    .play-button {
      width: 100px;

      &.playing {
        background: $primary700;
      }
    }

    @media (max-width: 769px) {
      margin-top: -24px;
      justify-content: center;
    }
  }

  .scrubber-header-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
    margin-top: 16px;
    text-align: left;
    padding-left: unset;

    @media (min-width: 769px) {
      padding-left: 24px;
    }

    .header-value {
      display: flex;
      flex-direction: column;
      padding: 0 12px;
      text-align: start;

      .header-label-text {
        color: $gray400;
      }

      .date-text {
        color: $gray400;
        margin-right: 4px;
      }

      p {
        margin-top: 0;
      }

      .text-overflow {
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        max-width: unset;

        @media (max-width: 769px) {
          max-width: 125px;
        }
      }
    }
  }

  .speed-chart-container {
    position: relative;
    height: 130px;
    margin-top: 25px;

    .bottom-axis-label {
      user-select: none;
    }
  }
}

.scrubber,
.timeline-container {
  overflow: visible;
  white-space: nowrap;
}

.helm-event-markers-container {
  position: relative;
  bottom: 34px;
  width: 100%;
}

.helm-event-marker-container {
  position: absolute;
  width: fit-content;
}

.scrubber {
  position: absolute;
  height: 100%;
}

.timeline-container {
  position: relative;
  height: max-content;
  cursor: grab!important;

  &.dragging {
    cursor: grabbing!important;
  }

  .scrubber-tread-container {
    align-content: center;
    position: relative;
  }

  .scrubber-activity {
    position: absolute;
    padding-left: 8px;
    border-radius: 16px;
    mix-blend-mode: multiply;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;

    &.loading {
      color: transparent;
      animation: loading-animation 0.75s linear infinite alternate;
    }

    @keyframes loading-animation {
      0% {
        background: $gray050;
      }
      100% {
        background: $gray300;
      }
    }

    span {
      mix-blend-mode: color-burn;
    }
  }
}

.line {
  height: 1px;
  background: $gray100;
  margin-bottom: 18px;
}

.scrubber-time-text {
  margin-top: 0;
  display: inline-block;
}

.scrubber-needle {
  position: absolute;
  top: 24px;
  z-index: 1;
}

.zoom-button {
  width: 44px;

  &.chakra-button,
  &.chakra-button:focus {
    border-radius: 8px;
    border: 1px solid $gray300;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}
