.time-container {
  width: 145px;
}

.time-text {
  color: $gray900;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
