.rate-app-container {
  position: fixed;
  z-index: 100;
  width: 570px;

  .chakra-card__footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: $baseWhite;
    order: 2;
    border-radius: 0px 0px 15px 15px;
  }

  .chakra-card__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $baseWhite;
    order: 0;
    border-radius: 15px 15px 0px 0px;
  }
}

.rate-app-map-container {
  top: 1%;
  right: 2%;
}

.see-something-wrong-container {
  top: 15%;
  right: 40%;
}

.rate-app-card {
  width: 100%;
}

.rate-app-card-header {
  height: 80px;
  margin-bottom: 8px;
}

.card-close-btn {
  margin-top: 8px;
}

.rate-app-icon {
  margin: 8px;
}

.confirm-feedback-container {
  z-index: 999;

  .chakra-card__body {
    padding-bottom: 15px;

    svg {
      display: block;
      margin: 20px auto auto auto;
    }

    .heading {
      text-align: center;
    }
  }

  .chakra-card__footer {
    order: 2;
    padding: 0 15px 20px 15px;
  }
}

.rate-app-value-modal-content {
  .rate-app-value-modal-body-btn {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;

    button {
      width: 38px;
      border-radius: 8px;
      border: 1px solid $primary600;
      background: $primary600;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }

  .rate-app-value-modal-body-likely {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: $gray700;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    padding-top: 6px;
    padding-bottom: 24px;
  }

  p {
    color: $gray900;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
  }
}

.modal-close-btn {
  margin-top: 18px;
}

/************************************************************************ MOBILE SPECIFIC STYLES ******************************************************************************/
@media (max-width: 769px) {
  .see-something-wrong-container {
    top: 156px;
    right: unset;
  }

  .rate-app-container {
    z-index: 100;
    width: 100%;
    top: 25%;
  }

  .rate-app-map-container {
    z-index: 100;
    top: 25%;
    right: 5%;
    width: 90%;
  }

  .rate-app-value-modal-body-btn {
    .chakra-button {
      width: 44px;
      height: 40px;
    }
  }
}
