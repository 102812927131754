@import 'rsuite/dist/rsuite.css';
@import '../../../global/global-color-styles.scss';

.selected-secondary-btn {
  &.chakra-button {   
    border: 1px solid $gray300;
    background: $gray200; 
  }
}

.search-icon {
  &.chakra-input__left-element {
    margin-left: 5px;
    margin-right: 10px;
  }

  svg {
    height: 20px;
  }
}

.filter-suggestion-list {
  text-align: left;
  width: 100%;
}

.menu-button {
  &.chakra-menu__menu-button {
    width:100%;
    background: $gray025;
    border: 1px solid $gray300;  
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    &:hover, &:focus {      
      background: $gray025;
      border: 1px solid $gray300;  
    }
  }

  span div p {
    color: $baseBlack;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
}

.chakra-menu__icon-wrapper svg {  
    color: $primary600;
}

.filter-field-value {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.75px;
  color: $gray900;
}

.chakra-menu__menu-list {
  max-height: 400px;
  overflow: auto;
}

.filter-menu-list-item:hover {
  cursor: pointer;
}

.rs-picker-daterange-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 25%;
  min-width: 250px;
  max-width: 270px;
  height: 40px;
  background: rgb(252, 252, 252);

  margin-left: 12px;
  position: relative;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: $gray700;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.rs-picker-default .rs-picker-toggle {
  align-items: flex-end;
  justify-content: center;
  display: flex;
}

.rs-picker-toggle-placeholder {
  color: $gray600;
  font-size: 10px;
  position: absolute;
  font-weight: 500;
  margin-top: -25px;
  display: none;
}

.rs-picker-toggle-textbox {
  display: none;
}

.rs-picker-daterange-wrapper.rs-picker-daterange-wrapper.rs-picker.rs-picker-daterange.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-bottom-end.rs-picker-has-value {
  [role='combobox'] {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    width: 100%;
    height: 40px;
    padding-right: 20px;
    border: 1px solid $gray200;
    border-radius: 8px;
    flex: none;
    order: 3;
    flex-grow: 1;
  }
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  background: $gray025;
  height: 40px;
}

.rs-picker-toggle>.rs-stack {
  width: 100%;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: rgb(226, 232, 240);
}

.rs-picker-daterange-menu {
  margin-top: 20px;
}

.rs-picker-toolbar {
  justify-content: right;
}

.rs-picker-toolbar-ranges>.rs-stack-item>.rs-btn-link {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  width: 100px;
  height: 40px;
  background: $baseWhite;
  border: 1px solid $gray300;
  border-color: $primary600;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: $primary600;
  margin-right: 5px;
  margin-left: 5px;
}

.rs-picker-toolbar-ranges>.rs-stack-item>.rs-btn-link:hover {
  cursor: pointer;
  background: $gray200;
}

.rs-picker-toolbar-right>.rs-btn-primary {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  width: 100px;
  height: 40px;
  background: $primary600;
  border: 1px solid $gray300;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: $gray025;
  margin-right: 5px;
  margin-left: 5px;
}

.rs-picker-toolbar-right>.rs-btn-primary:hover {
  cursor: pointer;
  background: $primary700;
}

.rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar-ranges {
  width: unset;
}

.rs-input-group-lg.rs-input-group > .rs-input {
  height: 100%;
  color: $gray700;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

/************************************************************************ MOBILE SPECIFIC STYLES ******************************************************************************/
@media (max-width: 769px) {  
  .rs-picker-daterange-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    max-width: 233.67px;

    height: 40px;
    background-color: transparent;

    margin-left: auto;
    margin-right: 5%;
    position: relative;
    z-index: 0;
  }

  .header {
    background-color: $baseWhite;
    width: 100%;
    height: 75px;
    padding-top: min(10px, 2%);
    justify-content: stretch;
    margin-bottom: 15px;
  }

  .search-icon {
    &.chakra-input__left-element {
      margin-left: 5px;
      margin-right: 10px;
      margin-top: 1px;
      top: unset;
    }
  }

  .menu-button {
    &.chakra-menu__menu-button {
      margin-left: unset;

      &.trip-type-menu-button {
        width: 90%;
      }
    }
  }
}