.global-error-handler-modal {
  .modal-close-button {
    margin-top: 10px;
    margin-right: 5px;
  }
}
.global-error-handler-simple-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  padding: 24px;
  background-color: $baseWhite;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1000;

  h2 {
    margin-bottom: 8px;
    color: $gray900;
    float: left;
  }

  p {
    margin-bottom: 20px;
    color: $gray600;
    float: left;
  }

  button {
    width: 100%;
    padding: 12px;
    background-color: $baseWhite;
    border: 1px solid $gray300;
    border-radius: 8px;
    cursor: pointer;
    color: $gray900;
  }

  button:hover {
    background-color: $gray050;
  }
}
