@import './../../../global/global-color-styles.scss';

.bar-chart-date-picker-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-left: 2px;
  padding-right: 8px;
  margin-bottom: 12px;

  .bar-chart-header-container {
    margin-left: 20px;
  }

  .bar-chart-carousel-container { 
    position: relative;
    display: flex;
    transition: transform 0.7s ease;
    transform: translateX(calc(-100%));

    &.sliding {
      transition: none;
    }

    &.previous {
      transform: translateX(calc(2 * (-100%)));
    }

    &.next {
      transform: translateX(0%);
    }
  }

  .bar-chart-container {
    flex: 1 0 100%;
    cursor: grab;
  }

  .eco-ratings-label {
    color: $gray700;

    svg {
      display: inline-block;
      vertical-align: middle;
      margin-left: 4px;
    }
  }

  .bottom-axis-label {
    fill: $gray700;
    user-select: none;
  }

  .slide-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 165px;
    width: 20px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid $gray300;
    background: $baseWhite;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

    &.left {
      left: 8px;
    }

    &.right {
      right: 8px;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .co2-surplus-text {
    color: $gray700;
  }

  .eco-rating-container {
    margin-top: 8px;
  }

  .progress-status-container {
    width: fit-content;
    height: 24px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 8px;
    padding-right: 10px;
    mix-blend-mode: multiply;
    border-radius: 16px;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    display: inline-flex;

    &.positive {
      background: $success050;
    }

    &.negative {
      background: $error050;
    }

    .progress-icon {
      width: 12px;
      height: 12px;
      position: relative;
    }

    .progress-value {
      text-align: center;
      word-wrap: break-word;

      &.positive {
        color: $success700;
      }
  
      &.negative {
        color: $error700;
      }
    }
  }
}

.chakra-portal {
  .eco-rating-tooltip {

    .eco-rating-tooltip-text {
      color: $baseWhite;
    }

    .eco-rating-tooltip-dismiss {
      @extend .eco-rating-tooltip-text;
      cursor: pointer;
    }
  }
}
