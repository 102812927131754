$baseWhite: #FFFFFF;
$baseBlack: #000000;
$gray025: #FDFDFD;
$gray050: #F8F8F8;
$gray100: #F3F3F3;
$gray200: #E8E8E8;
$gray300: #D0D0D0;
$gray400: #999999;
$gray500: #6F6F6F;
$gray600: #515151;
$gray700: #3E3E3E;
$gray800: #252525;
$gray900: #141414;
$primary025: #FCFDFE;
$primary050: #F6FAFD;
$primary100: #EEF6FB;
$primary200: #CDE4F3;
$primary300: #ACD2EC;
$primary400: #8BC0E4;
$primary500: #6AAFDC;
$primary600: #459AD4;
$primary700: #236795;
$primary800: #1B5074;
$primary900: #133953;
$error025: #FFFDFD;
$error050: #FFF5F5;
$error100: #FDE9EA;
$error200: #FCD5D5;
$error300: #F7ABAC;
$error400: #EF5F61;
$error500: #EB3C3E;
$error600: #DB1517;
$error700: #B31214;
$error800: #8A1011;
$error900: #780F10;
$warning025: #FFFDF8;
$warning050: #FEF8EB;
$warning100: #FCEFD4;
$warning200: #F7E0AB;
$warning300: #F3D083;
$warning400: #F0C25E;
$warning500: #ECB43D;
$warning600: #DD9A14;
$warning700: #B47D11;
$warning800: #8B620F;
$warning900: #66480D;
$success025: #F6FEF6;
$success050: #E9FAE8;
$success100: #C7F2C6;
$success200: #92E590;
$success300: #71DD6F;
$success400: #41CF3F;
$success500: #2AC128;
$success600: #259C22;
$success700: #1D7A1C;
$success800: #175917;
$success900: #154A15;
$violet025: #FBFAFF;
$violet050: #F5F3FF;
$violet100: #ECE9FE;
$violet200: #DDD6FE;
$violet300: #C3B5FD;
$violet400: #A48AFB;
$violet500: #875BF7;
$violet600: #7839EE;
$violet700: #6927DA;
$violet800: #5720B7;
$violet900: #491C96;
$blueDark025: #F5F8FF;
$blueDark050: #EFF4FF;
$blueDark100: #D1E0FF;
$blueDark200: #B2CCFF;
$blueDark300: #84ADFF;
$blueDark400: #528BFF;
$blueDark500: #2970FF;
$blueDark600: #155EEF;
$blueDark700: #004EEB;
$blueDark800: #0040C1;
$blueDark900: #00359E;
$purple025: #FAFAFF;
$purple050: #F4F3FF;
$purple100: #EBE9FE;
$purple200: #D9D6FE;
$purple300: #BDB4FE;
$purple400: #9B8ABF;
$purple500: #7A5AF8;
$purple600: #6938EF;
$purple700: #5925DC;
$purple800: #4A1FB8;
$purple900: #3E1C96;
$yellow025: #FEFDF0;
$yellow050: #FEFEB8;
$yellow100: #FEF7C3;
$yellow200: #FEEE95;
$yellow300: #FDE272;
$yellow400: #FAC515;
$yellow500: #EAAA08;
$yellow600: #CA8504;
$yellow700: #A15C07;
$yellow800: #854A0E;
$yellow900: #713B12;
$pink025: #FEF6FB;
$pink050: #FDF2FA;
$pink100: #FCE7F6;
$pink200: #FCCEEE;
$pink300: #FAA7E0;
$pink400: #F760C7;
$pink500: #EE46BC;
$pink600: #DD2590;
$pink700: #C11574;
$pink800: #9E165F;
$pink900: #851651;
$orangeDark025: #FFFEF5;
$orangeDark050: #FFFEED;
$orangeDark100: #FFE6D5;
$orangeDark200: #FFD6AE;
$orangeDark300: #FFC696;
$orangeDark400: #FF892E;
$orangeDark500: #FF4405;
$orangeDark600: #E62E05;
$orangeDark700: #BC1B06;
$orangeDark800: #97180C;
$orangeDark900: #711A0D;

:export {
    baseWhite: $baseWhite;
    baseBlack: $baseBlack;
    gray025: $gray025;
    gray050: $gray050;
    gray100: $gray100;
    gray200: $gray200;
    gray300: $gray300;
    gray400: $gray400;
    gray500: $gray500;
    gray600: $gray600;
    gray700: $gray700;
    gray800: $gray800;
    gray900: $gray900;
    primary025: $primary025;
    primary050: $primary050;
    primary100: $primary100;
    primary200: $primary200;
    primary300: $primary300;
    primary400: $primary400;
    primary500: $primary500;
    primary600: $primary600;
    primary700: $primary700;
    primary800: $primary800;
    primary900: $primary900;
    error025: $error025;
    error050: $error050;
    error100: $error100;
    error200: $error200;
    error300: $error300;
    error400: $error400;
    error500: $error500;
    error600: $error600;
    error700: $error700;
    error800: $error800;
    error900: $error900;
    warning025: $warning025;
    warning050: $warning050;
    warning100: $warning100;
    warning200: $warning200;
    warning300: $warning300;
    warning400: $warning400;
    warning500: $warning500;
    warning600: $warning600;
    warning700: $warning700;
    warning800: $warning800;
    warning900: $warning900;
    success025: $success025;
    success050: $success050;
    success100: $success100;
    success200: $success200;
    success300: $success300;
    success400: $success400;
    success500: $success500;
    success600: $success600;
    success700: $success700;
    success800: $success800;
    success900: $success900;
    violet025: $violet025;
    violet050: $violet050;
    violet100: $violet100;
    violet200: $violet200;
    violet300: $violet300;
    violet400: $violet400;
    violet500: $violet500;
    violet600: $violet600;
    violet700: $violet700;
    violet800: $violet800;
    violet900: $violet900;
    blueDark025: $blueDark025;
    blueDark050: $blueDark050;
    blueDark100: $blueDark100;
    blueDark200: $blueDark200;
    blueDark300: $blueDark300;
    blueDark400: $blueDark400;
    blueDark500: $blueDark500;
    blueDark600: $blueDark600;
    blueDark700: $blueDark700;
    blueDark800: $blueDark800;
    blueDark900: $blueDark900;
    purple025: $purple025;
    purple050: $purple050;
    purple100: $purple100;
    purple200: $purple200;
    purple300: $purple300;
    purple400: $purple400;
    purple500: $purple500;
    purple600: $purple600;
    purple700: $purple700;
    purple800: $purple800;
    purple900: $purple900;
    yellow025: $yellow025;
    yellow050: $yellow050;
    yellow100: $yellow100;
    yellow200: $yellow200;
    yellow300: $yellow300;
    yellow400: $yellow400;
    yellow500: $yellow500;
    yellow600: $yellow600;
    yellow700: $yellow700;
    yellow800: $yellow800;
    yellow900: $yellow900;
    pink025: $pink025;
    pink050: $pink050;
    pink100: $pink100;
    pink200: $pink200;
    pink300: $pink300;
    pink400: $pink400;
    pink500: $pink500;
    pink600: $pink600;
    pink700: $pink700;
    pink800: $pink800;
    pink900: $pink900;
    orangeDark025: $orangeDark025;
    orangeDark050: $orangeDark050;
    orangeDark100: $orangeDark100;
    orangeDark200: $orangeDark200;
    orangeDark300: $orangeDark300;
    orangeDark400: $orangeDark400;
    orangeDark500: $orangeDark500;
    orangeDark600: $orangeDark600;
    orangeDark700: $orangeDark700;
    orangeDark800: $orangeDark800;
    orangeDark900: $orangeDark900;
}