@import './../../../global/global-color-styles.scss';

.trips-panel-container {
  width: 400px;
  height: 100vh;
  position: fixed;
  z-index: 99;
  background: $baseWhite;
  margin-left: -400px;
  transition: margin-left 250ms;

  @media (max-width: 769px) {
    width: 100vw;
    margin-left: -100%;
    position: fixed;
  }

  &.expanded {
    margin-left: 0;
  }

  .trips-panel-header-container {
    padding: 24px 16px 0;

    .header-section {
      width: 100%;
      height: 40px;
      margin-bottom: 12px;
      margin-top: 0;

      input.rs-input {
        display: none;
      }

      .rs-input-group-addon {
        cursor: pointer;
      }
    }

    .more-filters-container {
      width: 100%;
      margin-bottom: 12px;

      a {
        color: $primary800;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        text-decoration: none;
      }

      svg {
        margin-right: 8px;
        display: inline;
        color: $baseBlack;
      }
    }
  }
  
  .trips-panel-jobs-list-container {
    overflow-y: scroll;
    height: 100%;
  }

  .filter-container {
    margin-top: 16px;
  }

  .job-type-selector {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
    margin-top: 18px;
  }

  .trip-type-drop-down {
    width: 100%;
    margin-top: 18px;

    > div {
      z-index: 10;
    }
  }

  .no-trips-container {
    width: 100%;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
  }

  .trip-card {
    &.chakra-card {
      box-shadow: none;
    }
  }

  .bottom-spinner {
    margin: 20px 0 30px 0;
  }

  .trip-group-header {
    &.chakra-text {
      padding: 5px 10px 10px 20px;
      color: $gray700;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .trip-header-container {
    &.chakra-card__header {
      padding: 0 10px 0 7px;
      justify-content: center;
      align-items: center;
      gap: 3px;
      display: flex;
      border-radius: 6px;
      border: 1px solid $primary600;
      background: $baseWhite;
      width: fit-content;
      left: 25%;
      position: absolute;
      top: 0;
      z-index: 2;
      cursor: pointer;

      &.chakra-card__header:hover {
        background: $gray100;
      }
    }

    .trip-header {
      height: 28px;
      position: relative;
      align-content: center;

      .trip-header-item {
        display: inline-block;
        vertical-align: middle;
      }

      .trip-header-text {
        text-overflow: ellipsis;
        max-width: 220px;
        white-space: nowrap;
        overflow: hidden;
      }

      .trip-header-image {
        height: 24px;
        padding-right: 6px;
      }
    }
  }

  .job-container {
    &.chakra-card {
      height: 76px;
      flex-direction: unset;
      border-radius: 10px;
      border: 1px solid $gray200;
      background: $gray025;
      box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);

      :hover {
        background: $gray100;
        cursor: pointer;
      }
    }

    .job-icon {
      height: 18px;
      display: inline-block;
      vertical-align: top;
      margin-right: 8px;
    }

    .job-card {
      &.chakra-card__body {
        padding: 0;
        width: 100%;
      }

      > div {
        border-radius: 10px;
      }
    }

    .job-card.chakra-card__body > div {
      height: 100%;
      overflow: hidden;
    }

    .job-card-image {
      @media (min-width: 769px) {
        min-width: 53px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: blur(0.03rem);
        transform: scale(1.02);
      }
    }

    .job-card-text {
      color: var(--Gray-500, #6f6f6f);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      padding: 2px 8px 2px 0;
      display: inline-block;
      position: relative;
      vertical-align: super;
    }

    .job-card-item {
      @media (max-width: 769px) {
        &.trip-type {
          margin-left: 0;
        }
      }

      &.first-line {
        padding: 0.8rem 0 0 0;
      }

      margin-left: 10px;
      width: max-content;

      &.full-width {
        width: 100%;
        height: 40px;
      }

      .eco-rating-container {
        float: right;
        padding-right: 20px;

        .eco-rating-icon {
          display: inline-block;
          margin-right: 8px;
        }

        .eco-rating-text {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          display: inline-block;

          &.good {
            color: $success500;
          }

          &.neutral {
            color: $warning500;
          }

          &.bad {
            color: $error500;
          }
        }
      }
    }

    .progress-bar {
      border-radius: 0px 4px 4px 0px;
    }

    .progress-bar-good > [role='progressbar'] {
      @extend .progress-bar;
      background-color: $success300;
    }

    .progress-bar-neutral > [role='progressbar'] {
      @extend .progress-bar;
      background-color: $warning300;
    }

    .progress-bar-bad > [role='progressbar'] {
      @extend .progress-bar;
      background-color: $error300;
    }
  }

  .dim-search-result {
    opacity: 30%;
  }

  .vessel-name-search-container {
    .close-icon {
      cursor: pointer;
      svg {
        width: 10px;
      }
    }

    position: relative;
    width: 100%;

    ul {
      position: absolute;
      top: 45px;
      left: 0;
      z-index: 100;
      font-size: 16px;
      text-align: left;
      width: 100%;
      padding: 2px 0;
      max-height: 200px;
      overflow-y: auto;
      background: $baseWhite;
      border: 1px solid $gray300;
      border-radius: 8px;

      li {
        width: 100%;
        padding: 3px 8px 3px 20px;
        &:hover {
          cursor: pointer;
          background: $gray100;
        }
      }
    }
  }

  .bar-tooltip {
    display: flex;
    padding: 8px 12px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    background: $gray900;
    color: $baseWhite;
  }
}
