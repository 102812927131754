@import './../../../global/global-color-styles.scss';

.map-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.mapBlur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(15px);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-image: url(../../../static/img/background-map.jpg);
}

.centerText {
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.75px;
  color: $gray600;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.something-went-wrong-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  max-width: 456px;
  background: $baseWhite;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 12px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.something-went-wrong-body {
  width: 100%;
}

.something-went-wrong-image {
  width: 60%;
  height: 60%;
  margin-left: auto;
  margin-right: auto;
}

.something-went-wrong-footer-modal-footer {
  justify-content: center;
}

.something-went-wrong-footer-buttons {
  width: 50%;
  margin: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.something-went-wrong-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: $gray900;
}

.something-went-wrong-message {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: $gray600;
}

%base-pos-icons {
  z-index: 50;
  position: absolute;
  top: 8px;
  @media (max-width: 769px) {
    top: 12px;
  }
}

.chart-settings .chakra-popover__body .chart-selector-container {
  width: 96px;
  @media (min-width: 769px) {
    width: 115px;
    position: relative;
  }
}

.chart-map-selector-label {
  &.chakra-form__label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: $gray700;
    margin: 0;

    &.selected {
      color: $primary700;
    }
  }
}

.chart-settings-trigger-container {
  @extend %base-pos-icons;
  right: 8px;
}

.chart-settings-trigger {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.chart-settings {
  right: 3%;
  padding: 16px 12px;
  width: 401px;

  @media (max-width: 769px) {
    right: 2%;
    width: auto;
  }

  &.chakra-popover__content {
    border-radius: 16px;
    border: 1px solid $gray300;

    @media (min-width: 769px) {
      width: 401px;
    }

    &:focus-visible {
      box-shadow: 0 0 0 0px;
    }
  }

  .chakra-popover__header {
    border-bottom-width: 0;
  }
}

.chart-map-selector {
  padding: 10px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  margin-right: 12px;
  cursor: pointer;
  border-radius: 12px;
  border: 2px solid $gray200;
  background: $baseWhite;
  min-height: 105px;

  &.selected {
    border-radius: 12px;
    border: 2px solid $primary600;
    background: $primary050;
  }

  .chart-map-selector-icon {
    border-radius: 5px;
  }
}

.settings-group-label {
  &.chakra-form__label {
    color: $gray700;
    font-weight: 500;
    font-size: 14px;
    padding: 10px 0;
    line-height: 20px;
  }
}

.chart-setting-container {
  text-align: left;
}

.chart-setting {
  padding-bottom: 5px;
}

.setting-label {
  &.chakra-form__label {
    color: $gray700;
    font-weight: 500;
    font-size: 14px;
    align-self: baseline;
    line-height: 20px;
    margin-bottom: 0px;
  }
}

.setting-description {
  &.chakra-form__label {
    color: $gray600;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    float: left;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.vessel-search,
.pick-time,
.quick-access-panel {
  .action-button {
    white-space: nowrap;
    display: flex;
    padding-bottom: 10px;
    cursor: pointer;
    width: fit-content;

    p {
      display: inline-block;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
  .icon-collapse,
  .chakra-button {
    margin-bottom: 4px;
  }
  .icon-collapse-btn {
    float: right;
  }
}

.quick-access-panel-container {
  width: 100%;

  .feature-button-container {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 12px;
    align-self: stretch;
    flex-wrap: wrap;
  }

  .quick-access-content-panel-playback {
    @media (max-width: 769px) {
      width: 97vw;
      margin-left: 1vw;
      z-index: 99;
      position: absolute;
      top: 6vh;
      padding: 18px 16px;
    }
  }
  .quick-access-content-panel-live-view {
    @media (max-width: 769px) {
      width: 97vw;
      margin-left: 1vw;
      z-index: 99;
      position: absolute;
      bottom: -90vh;
      padding: 18px 16px;
    }
  }

  .quick-access-panel-trigger-live-view {
    &.chakra-button {
      position: absolute;
      top: 56px;
      right: 8px;

      @media (max-width: 769px) {
        top: unset;
        bottom: 14vh;
      }
    }
  }

  .quick-access-panel-trigger-playback {
    &.chakra-button {
      position: absolute;
      top: 56px;
      right: 8px;

      @media (max-width: 769px) {
        top: 56px;
        bottom: 12vh;
      }
    }
  }

  .quick-access-header-panel-playback {
    position: absolute;
    width: 363px;
    top: 56px;
    right: 0.4%;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid $gray300;
    background: $baseWhite;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    @media (max-width: 769px) {
      right: auto;
      position: absolute;
      width: 96.9vw;
      margin-left: 8px;
    }
  }
  .quick-access-header-panel-live-view {
    position: absolute;
    width: 363px;
    top: 56px;
    right: 8px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid $gray300;
    background: $baseWhite;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    @media (max-width: 769px) {
      bottom: 12vh;
      right: auto;
      top: auto;
      width: 96.9vw;
      margin-left: 8px;
    }
  }
  .quick-access-header-text {
    text-align: left;
    padding: 0px 12px;

    p {
      margin-top: 0;
    }
  }
  .quick-access-header-icon {
    text-align: right;
  }

  .recenter-vessel-icon {
    text-align: left;

    > button {
      border-radius: 8px;
      border: 1px solid $gray300;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }

  .chakra-popover__popper {
    @media (min-width: 769px) {
      position: absolute;
      width: 100%;
    }
  }
}

.quick-access-panel-header {
  color: $gray900;
  float: left;
  padding: 5px 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.quick-access-panel-text {
  color: $gray600;
  float: left;
  padding: 5px 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.quick-access-panel {
  &.chakra-popover__content {
    z-index: 99;
    position: absolute;
    top: 48px;
    right: 8px;
    width: 367px;
    padding: 12px 12px 0 12px;
    border-radius: 8px;
    border: 1px solid $gray300;
    background: $baseWhite;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    &:focus-visible {
      box-shadow: none;
    }

    @media (max-width: 769px) {
      top: auto;
      right: auto;
      width: 96.9vw;
      margin-left: 8px;
    }

    .chakra-popover__body {
      padding: 0;
    }
  }

  .pick-time-trigger {
    float: left;
  }

  .vessel-search-trigger,
  .feature-button-container {
    padding: 8px 0;
  }
}

.quick-access-panel.quick-access-content-panel-playback {
  &.chakra-popover__content {
    z-index: 99;
    position: absolute;
    top: 47px;
    right: 10px;
    width: 367px;
    padding: 12px 12px 0 12px;

    &:focus-visible {
      box-shadow: none;
    }

    @media (max-width: 769px) {
      position: absolute;
      right: auto;
      width: 96.9vw;
      margin-left: 8px;
    }

    .chakra-popover__body {
      padding: 0;
    }
  }

  .pick-time-trigger {
    float: left;
  }

  .vessel-search-trigger,
  .feature-button-container {
    padding: 8px 0;
  }
}

.pick-time {
  &.chakra-popover__content {
    padding: 20px 16px;
    position: absolute;
    right: 8px;
    top: 56px;
    width: 416px;
    height: 288px;

    &:focus-visible {
      box-shadow: none;
    }
    @media (max-width: 769px) {
      padding: 20px 16px;
      position: absolute;
      margin-left: 8px;
      top: 56px;
      width: 96.9vw;
      height: 288px;
      right: auto;
    }
  }

  .date-picker-container {
    width: 160px;
  }

  .time-picker-container {
    width: 100px;
  }

  .go-button {
    padding: 10px 12px;
  }

  .rs-picker-toggle-wrapper {
    height: 40px;
    border-radius: 8px;
    border: 1px solid $gray300;
    background: $baseWhite;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    input.rs-input {
      color: $gray500;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &:hover {
      border-color: $gray400;
    }

    div[role='combobox'] {
      height: 100%;
      width: 100%;
    }
  }

  .feature-button-container {
    padding: 8px 0;
  }

  .rs-input-group.rs-input-group-inside .rs-input-group-addon {
    padding: 10px 12px 10px 5px;
  }
}

.pick-time.pick-time-live-view {
  @media (max-width: 769px) {
    bottom: -92vh;
    top: auto;
  }
}

.pick-time-date-picker {
  .rs-stack > .rs-stack-item > .rs-picker-toggle-clean.rs-btn-close {
    top: 30%;
  }

  .rs-input-group.rs-input-group-inside {
    height: 100%;

    &:hover {
      border-color: $gray400;
    }
  }
}

.pick-time-date-picker-menu {
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active,
  .rs-calendar-month-dropdown-cell-active > .rs-calendar-month-dropdown-cell-content {
    background-color: $primary600;

    &:hover {
      background-color: $primary700;
    }
  }

  .rs-calendar-time-dropdown-cell,
  .rs-calendar-month-dropdown-cell > .rs-calendar-month-dropdown-cell-content,
  .rs-calendar-table-cell-content {
    &:hover {
      background-color: $primary200;
    }
  }

  .rs-calendar-month-dropdown-year-active,
  .rs-calendar-month-view .rs-calendar-header-title-date,
  .rs-calendar-time-view .rs-calendar-header-title-time {
    color: $primary700;
  }

  .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    box-shadow: none;
  }
}

div[role='dialog'].pick-time-date-picker-menu {
  z-index: 99;
  margin-top: 10px;

  @media (max-width: 769px) {
    margin-bottom: 10px;
  }
}

.vessel-search {
  &.chakra-modal__content {
    z-index: 99;
    position: absolute;
    top: -1%;
    right: 0.5%;
    width: 400px;
    padding: 16px;

    @media (max-width: 769px) {
      padding: 20px 16px;
      position: absolute;
      margin-left: 2px;
      top: -8px;
      width: 96.5vw;
      max-width: none;
      right: unset;
    }

    .chakra-modal__body {
      padding: 0;
    }
  }

  .hidden {
    display: none;
  }

  .vessel-search-trigger {
    &.chakra-input__group {
      position: absolute;
      width: 320px;
      top: 6%;
      right: 0.5%;
      background: $baseWhite;
      border-radius: 8px;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      input {
        border: 1px solid $gray300;
      }

      @media (max-width: 769px) {
        top: 185px;
        right: 3%;
        width: 93%;
      }
    }

    &.chakra-button {
      position: absolute;
      width: 145px;
      top: 6%;
      right: 0.5%;

      @media (max-width: 769px) {
        top: 185px;
        right: 3%;
        width: 93%;
      }
    }
  }

  .vessel-search-bar {
    &.chakra-input__group {
      width: 100%;
      outline-color: $gray300;
    }

    &.focused {
      margin-bottom: 15px;
    }

    input {
      &:focus,
      &:active,
      &:focus-visible {
        border: 2px solid $gray300;
        outline-color: $gray300;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
    }
  }

  .custom-scroll-bar {
    border: 0;
    width: 100%;
    max-height: 335px;

    .message-header {
      font-size: 16px;
      color: $gray900;
      font-weight: 600;
      line-height: 24px;
    }

    .message-subheader {
      font-size: 14px;
      color: $gray500;
      font-weight: 500;
      line-height: 20px;
    }

    p {
      font-style: normal;
    }

    .chakra-button {
      width: 99%;
      justify-content: flex-start;
      padding: 10px 16px;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid $gray300;
      background: $baseWhite;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: $gray700;
      margin-top: 2px;
    }
  }
}

.top-left-functions {
  .operation-selector {
    @extend %base-pos-icons;

    svg {
      margin-right: 8px;
    }

    p::first-letter {
      text-transform: capitalize;
    }
  }

  .trips-history-toggle {
    @extend %base-pos-icons;
  }

  .display-date {
    position: absolute;
    left: 56px;
    z-index: 1;
    @extend %base-pos-icons;
    padding-left: 9px;
    padding-right: 9px;
    border-radius: 6px;
    border: 1px solid $gray300;
    background: $baseWhite;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    @media (max-width: 769px) {
      line-height: 19px;
      max-width: 92px;
    }
  }
}

.map-left-controls-container {
  position: relative;
  transition: margin-left 250ms;

  &.expanded {
    @media (min-width: 769px) {
      margin-left: 400px;
    }
  }
}

.select-operation-message {
  position: relative;
  text-align: center;
  display: inline-flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: $gray100;
  top: 26px;

  @media (max-width: 769px) {
    top: 56px;
  }
}
