@import './../../../global/global-color-styles.scss';

.modal-icon {
  margin-left: 22px;
  margin-top: 16px;
}

.incorrect-information-modal {
  Textarea {
    height: 150px;
  }
}

.theme-button.chakra-button {
  border: 1px solid $primary200;
  background: $primary200;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: $baseWhite;
}

.theme-button-selected.chakra-button {
  border: 1px solid $primary600;
  background: $primary600;
}

.theme-button-area {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 15px;
  gap: 10px 13px;
}

.status-svg {
  position: absolute;
  right: 125px;
}

.chakra-badge.body-badge {
  border-radius: 8px;
  text-transform: none;
  flex: none;
  padding: 4px 8px;
  gap: 4px;
  min-width: 50px;
}

.chakra-badge.mobile-body-badge {
  border-radius: 8px;
  text-transform: none;
  flex: none;
  padding: 0px 5px;
  gap: 4px;
  min-width: 45px;
  font-weight: 500;
}

.status {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.75px;
  color: $gray600;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.status-red {
  @extend .status;
  color: $error500;
}

.status svg {
  margin-right: 5px;
}

/* MOBILE STYLES */
@media (max-width: 768px) {
  .incorrect-information-modal {
    Textarea {
      height: auto;
    }
  }
}