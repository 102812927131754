@import './../../../global/global-color-styles.scss';

.pb-page-background {
  text-align: center;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.no-data-message-container {
  position: absolute;
  z-index: 1;
  left: 40%;
  top: 8px;

  @media (max-width: 769px) {
    left: 8px;
    top: 56px;
    transform: none;
  }
  
  .no-data-message {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    padding: 10px;
    border-radius: 12px;
    border: 1px solid $error400;
    background: $error400;

    p {
      color: $baseWhite;
    }
  }
}

