@import './global-color-styles.scss';
/*
* If need a different variant, download zip from this link:
* https://fonts.google.com/specimen/Poppins
*/

@font-face {
  font-family: 'Poppins';
  src: url('../static/fonts/Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Poppins';
  src: url('../static/fonts/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Poppins';
  src: url('../static/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins';
  src: url('../static/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: url('../static/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url('../static/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: url('../static/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: url('../static/fonts/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

body {
  font-family: 'Poppins', 'Arial', sans-serif;
  font-style: normal;

  &.chakra-ui-light {
    overflow: hidden;
  }
}

.mapboxgl-map {
  font-family: 'Poppins', 'Arial', sans-serif;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.mapboxgl-ctrl-bottom-left {
  z-index: 0;
}

.metrics-container-playback {
  position: absolute;
  top: 90%;
  justify-content: center;
  width: 100%;

  @media (max-width: 768px) {
    top: auto;

    &.with-quick-access {
      bottom: 238px;
    }
  }
}

.metrics-container {
  position: fixed;
  top: 90%;
  justify-content: center;
  width: 100%;

  @media (max-width: 768px) {
    bottom: 8%;
    top: auto;

    &.with-quick-access {
      bottom: 238px;
    }
  }
}

@supports (-webkit-touch-callout: none) {
  .metrics-container {
    top: 80%;
  }
}

.metric {
  padding: 0px 10px;
  gap: 10px;
  width: 150px;
  height: 34px;
  border-radius: 16px;
  bottom: 100px;
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.75px;
  text-align: center;

  .align-element {
    margin-top: 0px;
    margin-inline: 0px;
    margin-bottom: 0px;
    margin-left: 8px;
  }
}

.timer {
  background: $baseWhite;
  border: 1px solid $gray400;

  .text-primary {
    color: $gray900;
  }

  .text-secondary {
    color: $baseBlack;
    opacity: 0.4;
  }

  &.focused {
    width: 170px;
    height: 40px;
    top: -8px;
    position: relative;
    border-width: 1px;
    cursor: default;

    p {
      top: 5px;
      font-size: 15px;
    }
    .clock-icon {
      top: -8px;
      right: -8px;
    }
  }
}

.speed-log {
  background: $gray300;
  border: 1px solid $gray500;
  justify-content: center;
  font-size: 15px;
  border-radius: 16px;

  .text-primary {
    color: $baseWhite;
  }

  .text-secondary {
    color: rgba(252, 252, 252, 0.5);
  }

  &.focused {
    height: 40px;
    top: -8px;
    position: relative;
    border-width: 1px;

    .speedLogSpeed {
      top: 5px;
      font-size: 18px;
    }
    .speed-log-icon {
      top: -8px;
      right: -8px;
    }
  }

  &.speed-log-green {
    background-color: $success300;
    border: 1px solid $success500;
    border-radius: 16px;
  }

  &.speed-log-red {
    background-color: $error400;
    border: 1px solid $error500;
    border-radius: 16px;
  }
}

.speed-log-icon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  bottom: 15px;
  left: 20px;

  svg {
    position: relative;
    width: 100%;
    height: 100%;
    top: -6px;
    color: $gray400;
  }
}

.chakra-menu__menuitem-option {
  &:hover {
    background-color: $gray100 !important;
  }
}

.custom-scroll-bar {
  font-size: 16px;
  margin-top: 0px !important;
  text-align: left;
  width: 100%;
  padding: 2px 0px;
  max-height: 200px;
  overflow-y: auto;
  left: 10px;
  background: $baseWhite;
  border: 1px solid $gray300;
  border-radius: 8px;
}

::-webkit-scrollbar {
  width: 10px;
}

/* MOBILE STYLES */
@media (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 15px;
  }
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $baseWhite;
}

::-webkit-scrollbar-thumb {
  background: $gray300;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: $gray400;
}

.btn-no-border.chakra-button {
  border: none;
  box-shadow: none;

  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
}

.modal-footer-btn {
  width: 100%;
}

.text-header {
  color: $gray900;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.text-xxl-regular {
  color: $gray900;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.text-xl-regular {
  color: $gray900;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.text-lg-regular {
  color: $gray900;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.text-md-regular {
  color: $gray900;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.text-sm-regular {
  color: $gray900;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.text-xs-regular {
  color: $gray900;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.text-lg-medium {
  color: $gray900;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.text-md-medium {
  color: $gray900;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.text-sm-medium {
  color: $gray900;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.text-xs-medium {
  color: $gray900;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.text-lg-semibold {
  color: $gray900;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.text-md-semibold {
  color: $gray900;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.text-sm-semibold {
  color: $gray900;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.text-xs-semibold {
  color: $gray900;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
