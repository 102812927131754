@import './../../../global/global-color-styles.scss';
$base-width: 300px;

.mapboxgl-popup-content > div {
  width: 100%;
}

.mapboxgl-popup-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 20px;
  gap: 8px;
  min-width: $base-width;
  background: $gray025;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  pointer-events: none;
}

.map-tooltip {
  z-index: 50;
}

.track-tooltip {
  @extend .map-tooltip;
  width: fit-content;

  .mapboxgl-popup-tip {
    display: none;
  }

  .mapboxgl-popup-content {
    min-width: 1px;
    height: 30px;
    color: $baseWhite;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;

    display: flex;
    padding: 8px 12px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    background: $gray900;
  }

  .popup-container {
    display: flex;
  }

  .track-tooltip-divider {
    align-self: center;
    padding: 0 5px
  }
}

.quiver-plot-tooltip,
.tidal-diamond-tooltip {
  @extend .map-tooltip;
  width: fit-content;

  .popup-container {
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }

  .mapboxgl-popup-tip {
    display: none;
  }

  .mapboxgl-popup-close-button {
    @extend .text-header;
    height: 50px;
    width: 50px;
    font-size: 35px;
    font-weight: 300;
  }

  .mapboxgl-popup-content {
    cursor: pointer;
    width: max-content;
    height: max-content;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-wrap: nowrap;
    align-self: stretch;
    pointer-events: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 12px;
    border: 1px solid $gray200;
    background: $baseWhite;
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
    padding: 20px 16px;

    .text-left {
      display: flex;
      height: 29px;
      justify-content: space-between;
      align-items: flex-end;
      align-self: stretch;
    }

    .text-right {
      display: flex;
      height: 29px;
      justify-content: flex-end;
      align-items: flex-end;
      align-self: stretch;
    }
  }
}

.tooltip-header-name {
  height: fit-content;
  white-space: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.75px;
  margin-right: 8px;
  color: $gray800;
  position: relative;
  text-align: left;
}

.tooltip-row {
  display: flex;
  width: 100%;
  margin-bottom: 6px;
}

.tooltip-badge {
  font-size: 13px;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  font-weight: 600;
  height: 24px;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.tooltip-property-key {
  height: 22px;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: $gray600;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.tooltip-property-value {
  height: 22px;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  text-align: right;
  letter-spacing: 0.25px;
  color: $gray400;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.activity-marker-tooltip {
  @extend .map-tooltip;
  width: fit-content;
  height: fit-content;

  .mapboxgl-popup-tip {
    display: none;
  }

  .mapboxgl-popup-content {
    min-width: 1px;
    color: $baseWhite;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    
    padding: 8px 12px;
    border-radius: 8px;
    background: $gray900;
  }
}
